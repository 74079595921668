const config = {
    appName: "CRT Business Tool - prd",
    region: "us-east-1",
    cognito: {
        userPoolId: "us-east-1_DbwPei0ST",
        domain: "client-reporting-user-pool-domain-prd.auth.us-east-1.amazoncognito.com",
        tokenUrl: "https://client-reporting-user-pool-domain-prd.auth.us-east-1.amazoncognito.com/oauth2/authorize",
        providerId: "ce-crt-pool-provider",
        redirectUrlSignIn: "https://crt.assetmgmt.principalam.com",
        redirectUrlSignOut: "https://crt.assetmgmt.principalam.com/logout",
        clientId: "1a1k9ieaijvjp8qdu8607d64f4",
        scope: "email openid aws.cognito.signin.user.admin",
        readOnlyGroup: null,
        updateGroup: null
    },
    api: {
        partyBaseAPI: "https://hjcbn5seze.execute-api.us-east-1.amazonaws.com/prd",
        domainBaseAPI: "https://79heqdifv9.execute-api.us-east-1.amazonaws.com/prd"
    }
};

export default config;
